@contact-input-width: 15rem;
@contact-input-width--medium: 17.5rem;
@contact-input-width--large: 20rem;
@contact-input-width--wide: 25rem;

.filter-page {
  background: @global-color-white;
  padding: @global-whitespace-medium 0;
  margin: @global-whitespace-medium / 2 0;
  min-height: calc(100vh - 160px);
  position: relative;
  border: 1px solid @global-border-color;

  &--appointments {
    margin: @global-whitespace-medium / 2 auto;
    width: 97%;
    background: @global-color-white;
    padding: @global-whitespace-medium 0;
    box-shadow: @app-shadow-heavy;
    min-height: 500px;
    position: relative;

    .rt-table {
      .rt-td {
        padding-top: @global-whitespace-xsmall;
        padding-bottom: @global-whitespace-xsmall;
      }
    }

    .row__text {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      white-space: normal;
      word-break: normal;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &--prescription-notifications {
    background: inherit;
    border: none;
    box-shadow: none;
  }

  &--contacts {
    margin: 0;
    box-shadow: none;

    .filter-page__dropdown {
      width: @contact-input-width--medium;
    }

    .daterange__dropdown {
      .dropdown__menu {
        width: @contact-input-width--large;

        .form__group {
          label {
            color: @global-color-gray-darker;
          }
        }
      }

      .filter-page__dropdown {
        width: @contact-input-width--large;
      }
    }

    .search__input {
      margin-bottom: 0;
    }

    .filter-page__dropdown__wrapper {
      .search__input {
        margin-bottom: 0;
      }
    }
  }

  &__header {
    padding: 0 @global-whitespace-medium;
    height: 3.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    &__text {
      font-size: @global-font-size-medium;
      font-weight: bold;
    }

    @media (max-width: @global-screen-xsmall-max) {
      height: inherit;
    }
  }

  &__filter {
    padding: 0 @global-whitespace-medium;

    &__label {
      line-height: @global-font-size-medium;
      margin-right: @global-whitespace-small;
      color: @global-color-gray;
      display: flex;
      flex-direction: row;
      align-items: center;
      align-content: center;
      height: 4rem;
      padding-bottom: @global-whitespace-xsmall;

      .clear-all__button {
        font-size: @global-font-size-small;
        line-height: @global-font-size-base;

        .button__text-wrapper {
          align-items: center;
        }
      }
    }

    &__container {
      flex-direction: row;
      display: inline-flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
    }
  }

  &__table {
    padding: 0 @global-whitespace-medium;
    font-size: @global-font-size-small;

    .rt-th {
      .rt-resizable-header-content {
        white-space: normal;
      }
    }
  }

  &__checkbox {
    .rhinobox__label {
      font-size: 0;
      margin-left: @global-whitespace-small;
    }
  }

  .resource-group {
    border: none;
  }

  .filter-page__dropdown,
  .filter-page__input {
    margin: 0 @global-whitespace-xsmall @global-whitespace-xsmall 0;
    margin-right: @global-whitespace-xsmall;
    min-width: @contact-input-width;
    margin-bottom: @global-whitespace-xsmall;

    .button__text-wrapper {
      justify-content: space-between;

      .dropdown__toggle__text {
        font-size: @global-font-size-small;
      }
    }
  }

  .filter-page__dropdown--wide {
    width: @contact-input-width--wide;
  }

  .filter-page__input {
    input {
      width: @contact-input-width;
      border-color: @button-default-border;

      &::placeholder {
        color: @global-color-gray;
      }

      :-ms-input-placeholder { /* Microsoft Edge */
        color: @global-color-gray;
      }
    }

    &--wide {
      input {
        width: @contact-input-width--wide;
      }
    }
  }

  @media (max-width: @global-screen-xsmall-max) {
    .filter-page__dropdown {
      width: @contact-input-width;
    }
  }

  .empty-message__wrapper {
    margin-top: @global-whitespace-large;
  }
}
